/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Montserrat Alternates', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

*::-webkit-scrollbar {
	width: 0.2em;
	height: 5px;
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

*::-webkit-scrollbar-thumb {
	background-color: #49A5FB;
	outline: 1px solid #49A5FB;
}


.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

/* Popover */

.nwy-inner .ant-popover-inner {
	padding: 0;
	border-radius: 10px;
	overflow: hidden;
}

.nwy-inner .ant-popover-inner-content {
	padding: 0;
}

.single-card-popover .ant-popover-inner {
	background-color: transparent;
}

.single-card-popover .ant-popover-inner-content {
	background-color: #1BA0E2;
	text-align: color;
	font-size: 14px;
	padding: 8px;
	border-radius: 6px;
	color: #fff;
}

.single-card-popover .ant-popover-arrow {
	background-color: #1BA0E2;
}

.single-card-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.single-card-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.single-card-popover.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #1BA0E2;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: #1BA0E2;
}

.single-card-popover.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.single-card-popover.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.single-card-popover.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #1BA0E2;
	border-right-color: #1BA0E2;
	border-bottom-color: transparent;
	border-left-color: transparent;
}

.single-card-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.single-card-popover.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.single-card-popover.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
	border-top-color: transparent;
	border-right-color: #1BA0E2;
	border-bottom-color: #1BA0E2;
	border-left-color: transparent;
}

.single-card-popover .ant-popover-inner-content .main-text-popover {
	font-size: 16px;
	font-weight: 700;
	text-align: center;
}

.single-card-popover .ant-popover-inner-content .buttons-popover {
	text-align: center;
	margin-left: -8px;
	margin-right: -8px;
}

.single-card-popover .ant-popover-inner-content .buttons-popover a {
	color: #fff;
	text-decoration: none;
}

.single-card-popover .ant-popover-inner-content .buttons-popover a:hover {
	text-decoration: none;
}

.single-card-popover .ant-popover-inner-content .buttons-popover li {
	padding-left: 16px;
	padding-right: 16px;
}

.single-card-popover .ant-popover-inner-content .buttons-popover li:not(:last-child) {
	margin-bottom: 3px;
}

.single-card-popover .ant-popover-inner-content .buttons-popover li:hover {
	cursor: pointer;
	background-color: #29abe8;
}

.filter-popover {
	max-width: 200px;
}

.filter-popover .ant-checkbox-wrapper {
	display: block;
}

.filter-popover .ant-popover-inner-content {
	padding: 12px 8px;
}

.filter-popover .ant-checkbox-wrapper {
	margin: 0;
}

.nwy-title {
	margin-bottom: 20px;
	color: #522342;
	font-size: 20px;
	letter-spacing: 0.4px;
	font-weight: 700;
}

.nwy-title .plus-circle {
	margin-left: 10px;
	opacity: 0.9;
	color: #522342;
	cursor: pointer;
	display: inline-block;
	transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
}

.nwy-title .plus-circle:hover {
	opacity: 1;
	color: #49A5FB;
}

.button-container {
	text-align: center;
}

.button {
	text-align: center;
	border: none;
	border-radius: 4px;
	display: inline-block;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.button:hover {
	text-decoration: none;
}

.ant-layout-footer {
	margin-top: 20px;
}

.input-delete-button {
	cursor: pointer;
	position: relative;
	top: 4px;
	font-size: 20px;
	color: #0099de;
	transition: all .3s;
	}

.input-delete-button:hover {
	opacity: 0.8;
	}

.input-delete-button[disabled] {
	cursor: not-allowed;
	opacity: 0.5;
}

.ant-modal-content {
	background: #f9f8f8!important;
}

.close-button {
	position: absolute;
	right: 5px;
	top: 5px;
	cursor: pointer;
	opacity: 0.7;
	font-size: 12px;
	transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
}

.close-button:hover {
	opacity: 1;
}

.ant-pagination {
	text-align: right;
	margin-top: 40px;
}

.isDisabled {
	pointer-events: none!important;
	opacity: 0.7!important;
}

.danger {
	color: red;
	font-size: 12px;
	font-style: italic;
}
